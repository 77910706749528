import React, {useEffect, useState} from 'react';
import {
    Cell,
    Avatar,
    List,
    Badge,
    IconButton,
    IconContainer,
    Section,
    ButtonCell, Skeleton, Modal, Button, Caption, Select, Spinner
} from '@telegram-apps/telegram-ui';
import {
    TbArrowBigRightLinesFilled,
    TbBasketSearch,
    TbAlertOctagonFilled,
} from "react-icons/tb";
import {
    FcBusinessContact,
    FcMultipleInputs,
    FcCustomerSupport,
    FcVoicePresentation, FcOvertime, FcSignature, FcVip, FcSynchronize, FcIdea, FcAbout, FcEngineering
} from "react-icons/fc";
import InfoFooter from "../component/InfoFooter";
import toast from "react-hot-toast";
import {
    get_district_teacher_list_api,
    get_outstanding_list_api,
    is_admin_api,
    sign_api,
    update_user_city_id
} from "../api/UserAPI";
import {useNavigate} from "react-router-dom";
import {
    ModalHeader
} from '@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader';
import copy from 'copy-to-clipboard';
import {get_city_list_api, get_parent_id_api, get_province_list_api} from "../api/AreaAPI";
import {HiServer, HiUserAdd, HiViewList} from "react-icons/hi";
import {useAliveController} from "react-activation";
import {NavigateOptions} from "react-router/dist/lib/context";
import {get_online_session_count_api} from "../api/AdminAPI";
import WebApp from "@twa-dev/sdk";
import IssueOrder from "./IssueOrder";

function Main() {
    const navigate = useNavigate()
    const aliveController = useAliveController()
    const [user, setUser] = useState({} as ResponseUserEntity)
    const [userLogin, setUserLogin] = useState(false)
    const [admin, setAdmin] = useState({} as ResponseAdminEntity)
    const [userModalVisible, setUserModalVisible] = useState(false)
    const [provinceList, setProvinceList] = useState([] as ResponseAreaNodeEntity[])
    const [provinceDefaultValue, setProvinceDefaultValue] = useState(0)
    const [cityList, setCityList] = useState([] as ResponseAreaNodeEntity[])
    const [districtTeachers, setDistrictTeachers] = useState({} as ResponseFunctionListEntity<ResponseDistrictItemEntity>)
    const [outstandingList, setOutstandingList] = useState([] as ResponseTeacherEntity[])
    useEffect(() => {
        const userStr = sessionStorage.getItem("User");
        if (userStr == null) {
            navigate('/Fake', {replace: false})
        } else {
            const user: ResponseUserEntity = JSON.parse(userStr)
            setUserLogin(true)
            setUser(user)
            loadAdminInfo()
            loadOutStandingList()
        }

    }, [])
    const loadAdminInfo = () => {
        is_admin_api()
            .then((res) => {
                setAdmin(res.data.data)
                loadDistrictTeachersList()
            })
    }
    const loadProvinceList = (user: ResponseUserEntity) => {
        get_province_list_api()
            .then((res) => {
                setProvinceList(res.data.data)
                get_parent_id_api({request_area_id: user.city_id} as RequestAreaEntity)
                    .then((res) => {
                        setProvinceDefaultValue(res.data.data)
                        get_city_list_api({request_area_id: res.data.data} as RequestAreaEntity)
                            .then((res) => {
                                setCityList(res.data.data)
                            })
                    })
            })
    }
    const loadDistrictTeachersList = () => {
        get_district_teacher_list_api()
            .then((res) => {
                setDistrictTeachers(res.data.data)
            })
    }
    const loadOutStandingList = () => {
        get_outstanding_list_api()
            .then(res => {
                setOutstandingList(res.data.data)
            })
    }
    const onSignBtnClick = () => {
        WebApp.HapticFeedback.impactOccurred("soft")
        sign_api().then(res => {
            toast.success(res.data.message)
            setUser(res.data.data)
        })
    }
    const onToTeacherSelectorBtnClick = (district_id: number, district_name: string, city_name: string) => {
        WebApp.HapticFeedback.impactOccurred("soft")
        navigate("/TeacherSelector", {
            state: {
                district_id: district_id,
                district_name: district_name,
                city_name: city_name
            }
        })
    }
    const onUpdateUserCityIdBtnClick = () => {
        WebApp.HapticFeedback.impactOccurred("soft")
        WebApp.showConfirm("确定要求改显示区域？\n每30天仅能修改一次！", (r) => {
            if (r) {
                WebApp.HapticFeedback.impactOccurred("soft")
                update_user_city_id({request_area_id: user.city_id} as RequestAreaEntity)
                    .then((res) => {
                        toast.success(res.data.message)
                        aliveController.dropScope("Main")
                        navigate("/")
                    })
            } else {
                return;
            }
        })
    }
    const onProvinceListChange = (val: string) => {
        get_city_list_api({request_area_id: Number(val)} as RequestAreaEntity)
            .then((res) => {
                setProvinceDefaultValue(Number(val))
                setCityList(res.data.data)
                onCityListChange(res.data.data[0].area_id + "")
            })
    }
    const onCityListChange = (val: string) => {
        user.city_id = Number(val)
        setUser(user)
    }

    const onToManagementPageBtnClick = (pageName: string, options?: NavigateOptions) => {
        WebApp.HapticFeedback.impactOccurred("soft")
        setUserModalVisible(false)
        navigate("/" + pageName, options)
    }
    const onToTeacherDetailBtnClick = (teacher_id: string) => {
        WebApp.HapticFeedback.impactOccurred("soft")
        navigate("/TeacherDetail", {
            state: {
                teacher_id: teacher_id,
            }
        })
    }
    return (
        <div>
            <List style={{minHeight: "100vh"}}>
                <Skeleton visible={!userLogin}>
                    <Section header={"用户信息"}
                             footer={"每日签到可获得积分"}>
                        <Cell before={<FcVoicePresentation size={40}/>}
                              description={
                                  <div style={{display: "flex", flexDirection: "column"}}>
                                      <Caption level="1" weight="3">
                                          {"默认城市：" + user.city_string + " 积分剩余：" + user.score}
                                      </Caption>
                                      <Caption level="1" weight="3">
                                          {(user.vip_timeout == null ? "（普通用户）" : "会员到期：" + user.vip_timeout)}
                                      </Caption>
                                  </div>}
                              multiline
                              onClick={() => {
                                  WebApp.HapticFeedback.impactOccurred("soft")
                                  setUserModalVisible(true)
                              }}>
                            {user.name}
                        </Cell>
                    </Section>
                </Skeleton>
                {
                    outstandingList.length > 0 &&
                    <Section header="优秀茶师" footer="以上茶师根据体验报告及相关点评推荐">
                        {outstandingList.map(item =>
                            <Cell Component="label"
                                  before={<Avatar size={48}
                                                  src={process.env.REACT_APP_BASE_URL + "/teacherThumbnailImage?teacherId=" + item.id}/>}
                                  after={<IconButton mode="bezeled" size="l"
                                                     onClick={() => {
                                                         onToTeacherDetailBtnClick(item.id)
                                                     }}> <TbBasketSearch
                                      size={"20"}/> </IconButton>}
                                  subtitle={item.remark}>

                                {item.name + "·" + item.town}
                            </Cell>)
                        }
                    </Section>
                }
                {/*<Section header="🍵推荐茶室" footer="以上茶室均已经过验证，是不错的选择">*/}
                {/*    <Cell Component="label"*/}
                {/*          before={<Avatar size={28}*/}
                {/*                          src={"https://th.bing.com/th/id/OIP.0YXCWLSz_ftanhmzpzp2PwHaJA?rs=1&pid=ImgDetMain"}/>}*/}
                {/*          description="高端会所、嫩妹多、品质上乘">*/}
                {/*        金光国际商务会所</Cell>*/}
                {/*    <ButtonCell before={<FcExport size={"20"}/>}>*/}
                {/*        点击查看更多茶室*/}
                {/*    </ButtonCell>*/}
                {/*</Section>*/}
                {
                    districtTeachers.itemList !== undefined && districtTeachers.itemList.length > 0 &&
                    <Section header={districtTeachers.before} footer={districtTeachers.after}>
                        {
                            districtTeachers.itemList.map(item =>
                                <Cell before={<IconContainer><TbArrowBigRightLinesFilled size={"20"}/></IconContainer>}
                                      after={<Badge type="number">{item.teacherCount}</Badge>}
                                      onClick={() => onToTeacherSelectorBtnClick(item.districtId, item.districtName, user.city_string)}
                                >
                                    {item.districtName}
                                </Cell>
                            )
                        }
                    </Section>
                }
                <Section header="客户服务" footer="如有其他问题可联系客服处理">
                    {/*<ButtonCell before={<FcMultipleInputs size={"20"}/>}*/}
                    {/*            onClick={() => onToManagementPageBtnClick("Service", {state: {type: 1,}})}>*/}
                    {/*    茶室入驻及相关服务*/}
                    {/*</ButtonCell>*/}
                    <ButtonCell before={<FcMultipleInputs size={"20"}/>}
                                onClick={() => onToManagementPageBtnClick("Service", {state: {type: 2,}})}>

                        茶艺师合作及相关服务
                    </ButtonCell>
                    <ButtonCell before={<FcCustomerSupport size={"20"}/>}
                                onClick={() => onToManagementPageBtnClick("Service", {state: {type: 3,}})}>
                        会员及积分相关问题
                    </ButtonCell>
                </Section>
                {admin.id != null &&
                    <Section header="管理员服务" footer="⚠各位管理员请务必认真审核相关资料！">
                        <Cell
                            after={admin.city_str}
                            description={"管理员等级：[LV." + admin.level + "]"}
                            onClick={e => {
                                copy(admin.url)
                                toast.success("已复制：\n" + admin.url)
                            }}
                        > {user.name} </Cell>
                        {
                            admin.level >= 1 &&
                            <div>
                                <ButtonCell before={<HiViewList size={"20"}/>} onClick={() => {
                                    onToManagementPageBtnClick("ErrorManagement")
                                }}>
                                    【错误信息】审核与管理
                                </ButtonCell>
                                <ButtonCell before={<HiViewList size={"20"}/>} onClick={() => {
                                    onToManagementPageBtnClick("ReportManagement")
                                }}>
                                    【体验报告】审核与管理
                                </ButtonCell>
                                <ButtonCell before={<HiServer size={"20"}/>}
                                            onClick={() => onToManagementPageBtnClick("TeacherManagement", {state: {admin_city_id: admin.city}})}>
                                    【茶艺师】管理
                                </ButtonCell>
                                <ButtonCell before={<HiServer size={"20"}/>}
                                            onClick={() => onToManagementPageBtnClick("UserManagement")}>
                                    【地区用户】管理
                                </ButtonCell>
                                <ButtonCell before={<HiServer size={"20"}/>}
                                            onClick={() => onToManagementPageBtnClick("OutstandingSetting")}>
                                    【推荐茶艺师】管理
                                </ButtonCell>
                            </div>
                        }
                        {
                            admin.level >= 2 &&
                            <div>
                                <ButtonCell before={<HiUserAdd size={"20"}/>}
                                            onClick={() => onToManagementPageBtnClick("AdminSetting")}>
                                    【管理员】设置
                                </ButtonCell>
                                <ButtonCell before={<HiUserAdd size={"20"}/>}
                                            onClick={() => onToManagementPageBtnClick("Miscellaneous")}>
                                    【杂项】超管杂项设置
                                </ButtonCell>
                                <ButtonCell before={<HiUserAdd size={"20"}/>}
                                            onClick={() => onToManagementPageBtnClick("IssueOrder")}>
                                    【补单】客户订单补单
                                </ButtonCell>
                                <ButtonCell before={<TbAlertOctagonFilled size={"20"}/>}
                                            onClick={() => {
                                                get_online_session_count_api().then(res => toast.success("目前在线Session：" + res.data.data))
                                            }}>
                                    【在线】获取Session计数
                                </ButtonCell>
                                <ButtonCell before={<TbAlertOctagonFilled size={"20"}/>}
                                            onClick={() => onToManagementPageBtnClick("PinPage")}>
                                    【关停】快速停止运行
                                </ButtonCell>
                            </div>
                        }
                    </Section>
                }
                <InfoFooter/>
            </List>
            <Modal open={userModalVisible}
                   onOpenChange={(open) => {
                       setUserModalVisible(open)
                       if (open) {
                           loadProvinceList(user)
                       }
                   }}
                   dismissible={true}
                   header={<ModalHeader></ModalHeader>}>
                <List style={{paddingBottom: '30px', background: 'var(--tgui--secondary_bg_color)'}}>
                    <Section header={"ID:" + user.id}>
                        <Cell
                            after={<Button mode="filled" size="s" onClick={onSignBtnClick}> 签到 </Button>}
                            description={"注册时间:" + user.reg_time}
                            subtitle={"积分余额:" + user.score}
                            multiline>
                            {"您好，" + user.name}
                        </Cell>
                    </Section>
                    <Section header={"当前显示区域：" + user.city_string}
                             footer={"【提示】每30天仅能修改一次区域，更新显示区域后小程序将重新登录。"}>
                        {
                            provinceList.length === 0 &&
                            <Cell>
                                <Spinner size="s"/>
                            </Cell>
                        }
                        {
                            provinceList.length !== 0 &&
                            <div>
                                <Select header="选择省级区域" value={provinceDefaultValue}
                                        onChange={e => onProvinceListChange(e.target.value)}
                                        style={{backgroundColor:"var(--tgui--section_bg_color"}}>
                                    {provinceList.map(item => <option value={item.area_id}>{item.name}</option>)}
                                </Select>
                                <Select header="选择市级区域" value={user.city_id}
                                        onChange={e => onCityListChange(e.target.value)}
                                        style={{backgroundColor:"var(--tgui--section_bg_color"}}>
                                    {cityList.map(item => <option value={item.area_id}>{item.name}</option>)}
                                </Select>
                                <ButtonCell before={<FcSynchronize size={"20"}/>} onClick={onUpdateUserCityIdBtnClick}>
                                    更新显示区域
                                </ButtonCell>
                            </div>
                        }
                    </Section>
                    <Section
                        header={user.vip_timeout == null ? "账户等级：普通用户" : ("VIP用户（到期时间：" + user.vip_timeout + "）")}>
                        {
                            user.vip_timeout == null &&
                            <div>
                                <ButtonCell before={<FcVip size={"20"}/>}
                                            onClick={() => onToManagementPageBtnClick("Service", {state: {type: 4}})}>
                                    解锁VIP会员
                                </ButtonCell>
                            </div>
                        }
                        {
                            user.vip_timeout != null &&
                            <ButtonCell before={<FcOvertime size={"20"}/>}
                                        onClick={() => onToManagementPageBtnClick("Service", {state: {type: 4}})}>
                                会员续费
                            </ButtonCell>
                        }
                        <ButtonCell before={<FcSignature size={"20"}/>} onClick={() => {
                            copy("" + user.id)
                            toast.success("已复制您的ID：" + user.id)
                        }}>
                            点此复制ID
                        </ButtonCell>
                    </Section>
                    <Section header={"其他"}
                             footer={"非常感谢每一位用户对本平台的大力支持，您的支持是平台持续运营的最大动力！"}>
                        <ButtonCell before={<FcAbout size={"20"}/>}
                                    onClick={() => {
                                        WebApp.showAlert("特别提醒\n---------------\n凡是需要支付订金或路费的都是骗子！\n在联系对方时，请勿告诉对方信息来源，仅表达朋友介绍的即可，成功率更高！")
                                    }}>
                            防骗宝典
                        </ButtonCell>
                        <Cell Component="label"
                              before={<FcIdea size={"20"}/>}
                              description="向机器人发送【/start】命令，获取专属分享链接，被邀请者开通会员时，您也可以收到会员礼品！详情咨询品茗Bot！"
                              multiline
                        >分享有惊喜</Cell>
                        <ButtonCell before={<FcBusinessContact size={"20"}/>}
                                    onClick={() => onToManagementPageBtnClick("TeacherCreator", {state: {type: 0}})}>
                            投稿茶师
                        </ButtonCell>
                        <ButtonCell before={<FcEngineering size={"20"}/>}
                                    onClick={() => {
                                        WebApp.showAlert("公测版提示\n---------------\n目前平台公测中，为保证服务质量，仅录入更新了川渝地区资料，其余地区数据目前正在逐步录入…\nVIP用户在新地区加入后，切换地区依旧享受会员免费解锁特权！\n感谢每位客户对平台发展的支持！---------------\n如果您有当地资源，想成为区域管理员，可联系川渝地区管理员，与超管详谈管理员要求、分成等相关情况。")
                                    }}>
                            关于公测版
                        </ButtonCell>
                    </Section>
                </List>
            </Modal>
        </div>
    );
}

export default Main;
