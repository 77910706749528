import request from '../utils/request';

export function get_errors_api(type: number) {
    return request<ResponseResult<ResponseErrorEntity[]>>({
        url: '/admin/getErrors?type=' + type,
        method: 'post',
    });
}

export function valid_errors_api(error_id: number, type: number) {
    return request<ResponseResult<ResponseErrorEntity[]>>({
        url: '/admin/validError?type=' + type + "&errorId=" + error_id,
        method: 'post',
    });
}

export function get_reports_api(type: number) {
    return request<ResponseResult<ResponseReportEntity[]>>({
        url: '/admin/getReports?type=' + type,
        method: 'post',
    });
}

export function valid_reports_api(report_id: number, type: number) {
    return request<ResponseResult<ResponseErrorEntity[]>>({
        url: '/admin/validReport?type=' + type + "&reportId=" + report_id,
        method: 'post',
    });
}

export function get_management_teacher_list_api(data: RequestManagementTeacherEntity) {
    return request<ResponseResult<ResponseTeacherEntity[]>>({
        url: '/admin/getManagementTeacherList',
        method: 'post',
        data
    });
}

export function get_management_teacher_detail_api(data: RequestTeacherEntityById) {
    return request<ResponseResult<ResponseTeacherConvergeEntity>>({
        url: '/admin/getTeacherDetail',
        method: 'post',
        data
    });
}

export function get_photo_base64_api_sync(teacher_id: string, photo_id: string) {
    return request<ResponseResult<string>>({
        url: '/teacherDetailImageBase64?teacherId=' + teacher_id + "&photoId=" + photo_id,
        method: 'post',
    });
}

export function update_teacher_api(data: RequestNewTeacherEntity) {
    return request<ResponseResult>({
        url: '/admin/updateTeacher',
        method: 'post',
        data
    });
}

export function get_user_list_api(page: number) {
    return request<ResponseResult<ResponseUserEntity[]>>({
        url: '/admin/getUserList?page=' + page,
        method: 'post',
    });
}

export function set_user_by_id_api(userId: number, type: number, value: number) {
    return request<ResponseResult<ResponseUserEntity[]>>({
        url: '/admin/setUserById?userId=' + userId + "&type=" + type + "&value=" + value,
        method: 'post',
    });
}

export function get_user_list_by_id_api(userId: number) {
    return request<ResponseResult<ResponseUserEntity[]>>({
        url: '/admin/getUserById?userId=' + userId,
        method: 'post',
    });
}

export function get_outstanding_list_api() {
    return request<ResponseResult<ResponseTeacherEntity[]>>({
        url: '/admin/getOutstandingList'
    });
}

export function set_outstanding_teacher_api(teacherId: string, type: number) {
    return request<ResponseResult>({
        url: '/admin/setOutstandingTeacher?teacherId=' + teacherId + "&type=" + type
    });
}

export function shutdown_api(pwd: string) {
    return request<ResponseResult>({
        url: '/super/shutdown?pwd=' + pwd
    });
}

export function get_admin_list_api() {
    return request<ResponseResult<ResponseAdminEntity[]>>({
        url: '/super/getAdminList'
    });
}

export function delete_admin_api(adminId: number) {
    return request<ResponseResult>({
        url: '/super/deleteAdmin?adminId=' + adminId
    });
}

export function set_admin_api(adminId: number, cityId: number, url: string) {
    return request<ResponseResult>({
        url: '/super/setAdmin?adminId=' + adminId + "&cityId=" + cityId + "&url=" + url
    });
}

export function get_online_session_count_api() {
    return request<ResponseResult<number>>({
        url: '/super/getOnlineSessionCount'
    });
}

export function release_user_api(userId: number) {
    return request<ResponseResult>({
        url: '/super/releaseUser?userId=' + userId
    });
}

export function reset_user_modified_time_api(userId: number) {
    return request<ResponseResult>({
        url: '/super/resetUserModifiedTime?userId=' + userId
    });
}

export function reset_user_score_api(userId: number) {
    return request<ResponseResult>({
        url: '/super/resetUserScore?userId=' + userId
    });
}
export function remove_user_api(userId: number) {
    return request<ResponseResult>({
        url: '/super/removeUser?userId=' + userId
    });
}
export function reset_user_unlock_api(userId: number) {
    return request<ResponseResult>({
        url: '/super/resetUserUnlock?userId=' + userId
    });
}

export function get_operation_list_by_admin_id(userId: number) {
    return request<ResponseResult<ResponseAdminOperationEntity[]>>({
        url: '/super/getOperationListByAdminId?adminId=' + userId
    });
}

export function get_transaction_data_by_hash_api(hash: string) {
    return request<ResponseResult<ResponseTransactionDataEntity>>({
        url: '/super/getTransactionDataByHash?hash=' + hash
    });
}

export function get_order_by_hash_api(hash: string) {
    return request<ResponseResult<ResponseOrderEntity>>({
        url: '/super/getOrderByHash?hash=' + hash
    });
}

export function issue_order_api(hash: string, userId: Number, type: number) {
    return request<ResponseResult>({
        url: '/super/issueOrder?hash=' + hash + "&userId=" + userId + "&type=" + type
    });
}