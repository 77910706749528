import React, {useEffect, useState} from 'react';
import {
    List,
    Section,
    LargeTitle,
    Subheadline,
    Cell,
    Button,
    Badge,
    Text,
    ButtonCell,
    Timeline,
    Modal,
    Textarea,
    Select,
} from '@telegram-apps/telegram-ui';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {
    FcBrokenLink,
    FcLockPortrait,
    FcAddDatabase,
    FcOvertime, FcHome, FcQuestions
} from "react-icons/fc";
import {FaTelegram, FaQq} from "react-icons/fa";
import {AiFillWechat, AiFillPhone, AiFillPushpin} from "react-icons/ai";
import {GiPotato, GiBatWing} from "react-icons/gi";
import {BiSolidChat} from "react-icons/bi";
import InfoFooter from "../component/InfoFooter";
import {useLocation} from "react-router-dom";
import {
    get_self_info_api,
    get_teacher_detail_api,
    submit_error_api,
    submit_report_api, unlock_teacher_api
} from "../api/UserAPI";
import {TbReportAnalytics} from "react-icons/tb";
import {
    ModalHeader
} from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
import WebApp from "@twa-dev/sdk";
import {useAliveController} from "react-activation";

function TeacherDetail() {
    const {state} = useLocation();
    const aliveController = useAliveController()
    const [teacherConvergeEntity, setTeacherConvergeEntity] = useState({} as ResponseTeacherConvergeEntity)
    const [teacherConnectEntity, setTeacherConnectEntity] = useState([] as ConnectEntity[])
    const [errorModalVisible, setErrorModalVisible] = useState(false)
    const [errorContent, setErrorContent] = useState("")
    const [reportModalVisible, setReportModalVisible] = useState(false)
    const [reportEntity, setReportEntity] = useState({score: 7} as RequestReportEntity)
    const [reportScore, setReportScore] = useState(7)
    useEffect(() => {
        get_teacher_detail_api({teacher_id: state.teacher_id} as RequestTeacherEntityById)
            .then((res) => {
                setTeacherConvergeEntity(res.data.data)
                setTeacherConnectEntity(JSON.parse(res.data.data.teacher.connect))
            })
    }, [])

    const onScoreChange = (score: number) => {
        setReportScore(score)
        reportEntity.score = score
        setReportEntity(reportEntity)
    }
    const onUnlockBtnClick = (teacher_id: string) => {
        WebApp.HapticFeedback.impactOccurred("heavy")
        WebApp.showConfirm("确定花费50金币解锁？", (r) => {
            if (r) {
                unlock_teacher_api(teacher_id)
                    .then(res => {
                        setTeacherConvergeEntity(res.data.data)
                        setTeacherConnectEntity(JSON.parse(res.data.data.teacher.connect))
                        toast.success(res.data.message)
                        get_self_info_api()
                            .then((res) => {
                                sessionStorage.setItem("User", JSON.stringify(res.data.data))
                                aliveController.dropScope("Main")
                            })
                    })
            } else {
                return;
            }
        })
    }
    const getConnectBtnNode = (type: string, value: string) => {
        WebApp.HapticFeedback.impactOccurred("soft")
        switch (type) {
            case "Phone":
                return <Button mode="filled" size="s" onClick={e => {
                    copy(value)
                    toast.success("已复制" + value)
                }}>
                    复制 </Button>
            case "Tencent":
                return <Button mode="filled" size="s" onClick={e => {
                    copy(value)
                    toast.success("已复制" + value)
                }}>
                    复制 </Button>
            case "Wechat":
                return <Button mode="filled" size="s" onClick={e => {
                    copy(value)
                    toast.success("已复制" + value)
                }}>
                    复制 </Button>
            case "Telegram":
                return <Button mode="filled" size="s" onClick={e => {
                    WebApp.openTelegramLink("https://t.me/" + (value.replace("@", "").trim()))
                }}>
                    跳转 </Button>
            case "Potato":
                return <Button mode="filled" size="s" onClick={e => {
                    copy(value)
                    toast.success("已复制" + value)
                }}>
                    复制 </Button>
            case "Bat":
                return <Button mode="filled" size="s" onClick={e => {
                    copy(value)
                    toast.success("已复制" + value)
                }}>
                    复制 </Button>
            case "WithYou":
                return <Button mode="filled" size="s" onClick={e => {
                    copy(value)
                    toast.success("已复制" + value)
                }}>
                    复制 </Button>
        }
    }
    const getConnectIconNode = (
        type: string
    ) => {
        switch (type) {
            case "Phone":
                return <AiFillPhone size={24}/>
            case "Tencent":
                return <FaQq size={24}/>
            case "Wechat":
                return <AiFillWechat size={24}/>
            case "Telegram":
                return <FaTelegram size={24}/>
            case "Potato":
                return <GiPotato size={24}/>
            case "Bat":
                return <GiBatWing size={24}/>
            case "WithYou":
                return <BiSolidChat size={24}/>
        }
    }
    const transformConnect = (type: string) => {
        switch (type) {
            case "Phone":
                return "联系电话"
            case "Tencent":
                return "QQ号"
            case "Wechat":
                return "微信号"
            case "Telegram":
                return "电报链接"
            case "Potato":
                return "土豆号"
            case "Bat":
                return "蝙蝠号"
            case "WithYou":
                return "与你号"
        }
    }
    return (
        <div>
            {
                teacherConvergeEntity !== undefined &&
                teacherConvergeEntity.teacher !== undefined &&
                teacherConvergeEntity.photoList !== undefined &&
                teacherConvergeEntity.reportList !== undefined &&
                teacherConvergeEntity.unlock !== undefined &&
                teacherConvergeEntity.score !== undefined &&
                <div>
                    <div style={{height: "50vh", width: "100%", position: "relative"}}>
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={1}
                        >
                            {
                                teacherConvergeEntity.photoList.map(photo_url =>

                                    <SwiperSlide style={{height: "50vh", width: "100%"}}>
                                        <img
                                            alt="左右滑动切换"
                                            src={process.env.REACT_APP_BASE_URL + "/teacherDetailImage?teacherId=" + teacherConvergeEntity.teacher.id + "&photoId=" + photo_url}
                                            style={{
                                                display: 'block',
                                                height: "100%",
                                                objectFit: 'cover',
                                                width: "100%"
                                            }}/>
                                    </SwiperSlide>
                                )
                            }
                        </Swiper>
                        <div style={{
                            padding: "20px 20px",
                            width: "calc(100% - 40px)",
                            position: "absolute",
                            bottom: "0px",
                            zIndex: "1",
                            color: "#FFFFFF",
                            background: "linear-gradient(to top, #3A3A3ADD, #3A3A3A00)",
                            backdropFilter: "blur(4px)"
                        }}>
                            <LargeTitle weight="1"> {teacherConvergeEntity.teacher.name} </LargeTitle>
                            <Subheadline level="1"
                                         weight="1">  {teacherConvergeEntity.teacher.town} </Subheadline>
                        </div>
                    </div>
                    <List>
                        <Section header="🍩网友评分">
                            {
                                teacherConvergeEntity.score > 0 &&
                                <Cell before={<TbReportAnalytics size={24}/>}
                                      after={<Badge type="number"
                                                    large={true}>{teacherConvergeEntity.score.toFixed(1)}
                                      </Badge>}
                                >
                                    服务评分
                                </Cell>
                            }
                            {
                                teacherConvergeEntity.score <= 0 &&
                                <Cell before={<TbReportAnalytics size={24}/>}
                                >
                                    很抱歉，目前暂无评分
                                </Cell>
                            }
                            <Cell subhead={teacherConvergeEntity.teacher.remark} multiline> </Cell>
                        </Section>
                        <Section header="🍕服务套餐">
                            {
                                teacherConvergeEntity.teacher.price_video != null &&
                                <Cell after={
                                    <Text style={{color: "var(--tgui--subtitle_text_color)"}}>
                                        {
                                            teacherConvergeEntity.teacher.price_video
                                        }
                                    </Text>
                                }
                                      description="通过网络视频通话"
                                > 网络指导 </Cell>
                            }
                            {
                                teacherConvergeEntity.teacher.price_easy != null &&
                                <Cell after={
                                    <Text style={{color: "var(--tgui--subtitle_text_color)"}}>
                                        {
                                            teacherConvergeEntity.teacher.price_easy
                                        }
                                    </Text>
                                }
                                      description="简单地指导沏茶"
                                > 简单指导 </Cell>
                            }
                            {
                                teacherConvergeEntity.teacher.price_normal != null &&
                                <Cell after={
                                    <Text style={{color: "var(--tgui--subtitle_text_color)"}}>
                                        {
                                            teacherConvergeEntity.teacher.price_normal
                                        }
                                    </Text>
                                }
                                      description="完整地指导冲泡全过程"
                                > 全套指导 </Cell>
                            }
                            {
                                teacherConvergeEntity.teacher.price_two != null &&
                                <Cell after={
                                    <Text style={{color: "var(--tgui--subtitle_text_color)"}}>
                                        {
                                            teacherConvergeEntity.teacher.price_two
                                        }
                                    </Text>
                                }
                                      description="通常包含一次沏茶与一次冲泡"
                                > 两次指导 </Cell>
                            }
                            {
                                teacherConvergeEntity.teacher.price_night != null &&
                                <Cell after={
                                    <Text style={{color: "var(--tgui--subtitle_text_color)"}}>
                                        {
                                            teacherConvergeEntity.teacher.price_night
                                        }
                                    </Text>
                                }
                                      description="在预约的时间内为您指导"
                                > 包时指导 </Cell>
                            }
                        </Section>
                        <Section header="🍫联系方式">
                            {
                                teacherConvergeEntity.teacher.connect == null &&
                                <div>
                                    <Cell before={<AiFillPhone size={20}/>}
                                          description="[解锁后查看] 目前您尚未解锁"
                                    > 联系方式 </Cell>
                                    <Cell before={<AiFillPushpin size={20}/>}
                                          description="[解锁后查看] 目前您尚未解锁"
                                    > 详细位置 </Cell>
                                    <ButtonCell before={<FcLockPortrait size={20}/>}
                                                onClick={e => {
                                                    onUnlockBtnClick(teacherConvergeEntity.teacher.id)
                                                }}>
                                        [解锁该茶艺老师联系方式]
                                    </ButtonCell>
                                </div>
                            }
                            {
                                teacherConvergeEntity.teacher.connect != null &&
                                <div>
                                    {
                                        teacherConnectEntity.map(item =>
                                            <Cell before={getConnectIconNode(item.type)}
                                                  after={getConnectBtnNode(item.type, item.value)}
                                                  description={item.value}>
                                                {transformConnect(item.type)}</Cell>
                                        )
                                    }
                                    <Cell before={<AiFillPushpin size={20}/>}
                                          after={<Button mode="filled" size="s" onClick={e => {
                                              copy(teacherConvergeEntity.teacher.address)
                                              toast.success("已复制地址:" + teacherConvergeEntity.teacher.address)
                                          }}>
                                              复制 </Button>}
                                          description={teacherConvergeEntity.teacher.address}
                                    > 详细位置 </Cell>
                                </div>
                            }

                        </Section>
                        <Section header="🍳最新体验" footer="体验评价来源于网友提交，不代表品茗官方的任何态度，请各位对内容自行甄别，谨防上当受骗。">
                            {
                                teacherConvergeEntity.reportList.length <= 0 &&
                                <div>
                                    <Cell before={<AiFillPushpin size={20}/>}
                                          description="您可以打分或添加报告"
                                    > 目前暂无报告 </Cell>
                                </div>

                            }
                            {
                                teacherConvergeEntity.reportList.length > 0 &&
                                <Timeline active={teacherConvergeEntity.reportList.length}
                                          style={{padding: "20px 20px"}}>
                                    {
                                        teacherConvergeEntity.reportList.map(report =>
                                            <Timeline.Item header={"网友评分：" + report.score + "分"}>
                                                <div style={{whiteSpace: "pre-wrap"}}>
                                                    {
                                                        "报告时间：" + report.report_time.split(" ")[0] + "\n" +
                                                        "报告内容：" + report.content
                                                    }
                                                </div>
                                            </Timeline.Item>
                                        )
                                    }
                                </Timeline>
                            }
                            <ButtonCell before={<FcAddDatabase size={20}/>}
                                        onClick={() => {
                                            setReportModalVisible(true)
                                        }}>
                                [为当前茶艺师添加评价]
                            </ButtonCell>
                        </Section>
                        <Section header="🎛️其他信息"
                                 footer="">
                            <Cell before={<FcOvertime size={"32"}/>}
                                  description="Tip：注册越早，预约成功率越高"
                            > {"注册时间：" + teacherConvergeEntity.teacher.reg_time.split(" ")[0]}</Cell>
                            <Cell before={<FcOvertime size={"32"}/>}
                                  description="Tip：更新越晚，稳定性越高"
                            > {"最近更新：" + teacherConvergeEntity.teacher.update_time.split(" ")[0]}</Cell>
                            <Cell before={<FcHome size={"32"}/>}
                                  description="⚠非全职可能需要您额外预订房间"
                            > {"茶艺师类型：" + ((teacherConvergeEntity.teacher.type === 1) ? "全职" : "兼职")}</Cell>
                        </Section>
                        <Section header="🍻错误反馈与信息更新" footer="平台尽可能及时更新相关信息，实际情况请以您和茶艺师的协商为准。如联系方式、服务价格、体验报告有误，请反馈给我们，经核实后将赠与积分。">
                            <ButtonCell before={<FcBrokenLink size={20}/>}
                                        onClick={() => {
                                            setErrorModalVisible(true)
                                        }}>
                                茶艺师失联或联系方式错误?
                            </ButtonCell>
                        </Section>
                        <InfoFooter/>
                    </List>
                    <Modal open={errorModalVisible}
                           dismissible={true}
                           onOpenChange={(open) => {
                               setErrorModalVisible(open)
                           }}
                           header={<ModalHeader></ModalHeader>}>
                        <List
                            style={{paddingBottom: '30px', background: 'var(--tgui--secondary_bg_color)'}}>
                            <Section header="请在下方为我们指出错误内容"
                                     footer="提交后，将由管理员审核。审核通过后，将赠送您积分！感谢您对品茗平台的大力支持！">
                                <Textarea header="茶艺师错误内容"
                                          placeholder="例如：茶艺师手机已更新为158****4321、茶艺师已无法联系、茶艺师选区错误应为XXX区等"
                                          value={errorContent}
                                          onChange={e => {
                                              setErrorContent(e.target.value);
                                          }}
                                          maxLength={64}/>
                                <ButtonCell before={<FcQuestions size={24}/>}
                                            onClick={() => {
                                                if (errorContent === "") {
                                                    toast.error("提交的信息不能为空！")
                                                    return
                                                }
                                                submit_error_api({
                                                    teacher_id: teacherConvergeEntity.teacher.id,
                                                    content: errorContent
                                                } as RequestErrorEntity)
                                                    .then((res) => {
                                                        toast.success(res.data.message)
                                                        setErrorContent("")
                                                        setErrorModalVisible(false)
                                                    })
                                            }}>
                                    点击提交
                                </ButtonCell>
                            </Section>
                            <div style={{minHeight: "150px"}}></div>
                        </List>
                    </Modal>
                    <Modal open={reportModalVisible}
                           dismissible={true}
                           onOpenChange={(open) => {
                               setReportModalVisible(open)
                           }}
                           header={<ModalHeader></ModalHeader>}>
                        <List
                            style={{paddingBottom: '30px', background: 'var(--tgui--secondary_bg_color)'}}>
                            <Section header="请在下方填写您本次体验的报告！"
                                     footer="提交后，将由管理员审核">
                                <Select header="请选择茶艺师评分"
                                        value={reportScore}
                                        onChange={event => {
                                            onScoreChange(Number.parseInt(event.target.value))
                                        }}>
                                    <option value={1}>1分，仙人跳、恶意诈骗</option>
                                    <option value={2}>2分，放鸽子，找理由推脱</option>
                                    <option value={3}>3分，见到真人，但已拒绝提供服务</option>
                                    <option value={4}>4分，提供服务，但态度极其恶劣</option>
                                    <option value={5}>5分，服务降级，或提供服务不全</option>
                                    <option value={6}>6分，完整服务，但态度一般</option>
                                    <option value={7}>7分，正常服务，中规中矩</option>
                                    <option value={8}>8分，服务到位，老师健谈</option>
                                    <option value={9}>9分，服务细致，性价比高</option>
                                    <option value={10}>10分，完美体验，无可挑剔</option>
                                </Select>
                                <Textarea header="报告内容"
                                          placeholder="例如：姐姐很热情，一进门就XXXX，很健谈等"
                                          value={reportEntity.content}
                                          onChange={e => {
                                              reportEntity.content = e.target.value
                                              setReportEntity(reportEntity)
                                          }}
                                          maxLength={300}
                                          style={{minHeight: 200}}
                                />
                                <ButtonCell before={<FcQuestions size={24}/>}
                                            onClick={() => {
                                                if (reportEntity.content === "" || reportEntity.score == null) {
                                                    toast.error("提交的信息不能为空！")
                                                    return
                                                }
                                                reportEntity.teacher_id = teacherConvergeEntity.teacher.id
                                                submit_report_api(reportEntity)
                                                    .then((res) => {
                                                        toast.success(res.data.message)
                                                        reportEntity.content = ""
                                                        setReportEntity(reportEntity)
                                                        setReportModalVisible(false)
                                                    })
                                            }}>
                                    点击提交
                                </ButtonCell>
                            </Section>
                            <div style={{minHeight: "150px"}}></div>
                        </List>
                    </Modal>
                </div>
            }

        </div>
    );
}

export default TeacherDetail;
