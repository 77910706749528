import React, {useState} from 'react';
import {
    List,
    Section,
    Input,
    Button, Cell, Select
} from '@telegram-apps/telegram-ui';
import InfoFooter from "../component/InfoFooter";
import {get_order_by_hash_api, get_transaction_data_by_hash_api, issue_order_api} from "../api/AdminAPI";
import toast from "react-hot-toast";
import WebApp from "@twa-dev/sdk";
import {update_user_city_id} from "../api/UserAPI";

function IssueOrder() {
    const [inputHash, setInputHash] = useState("")
    const [inputUserId, setInputUserId] = useState(0)
    const [inputType, setInputType] = useState(1)
    const [transactionData, setTransactionData] = useState({} as ResponseTransactionDataEntity)
    const [order, setOrder] = useState({} as ResponseOrderEntity)
    return (
        <List>
            <Section header="订单查询（根据Hash）">
                <Input header="输入付款交易Hash" placeholder="输入付款交易Hash"
                       value={inputHash}
                       type={"text"}
                       onChange={e => setInputHash(e.target.value)}/>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60px"}}>
                    <Button style={{width: "80%"}} mode="filled" size="m"
                            onClick={e => {
                                get_transaction_data_by_hash_api(inputHash)
                                    .then(res => {
                                        toast.success(res.data.message)
                                        setTransactionData(res.data.data)
                                    })
                                get_order_by_hash_api(inputHash)
                                    .then(res => {
                                        toast.success(res.data.message)
                                        setOrder(res.data.data)
                                    })
                            }}
                    >立即查询</Button>
                </div>
            </Section>
            {
                (transactionData === null || transactionData.amount === undefined) &&
                <Section>
                    <Cell>
                        未查询到该hash的支付记录
                    </Cell>
                </Section>
            }
            {
                (transactionData !== null && transactionData.amount !== undefined) &&
                <Section header="支付详情">
                    <Cell subhead={"金额(Amount)"}>
                        {transactionData.amount === undefined ? "未知" : transactionData.amount}
                    </Cell>
                    <Cell subhead={"付款(From)"}>
                        {transactionData.from === undefined ? "未知" : transactionData.from}
                    </Cell>
                    <Cell subhead={"收款(To)"}>

                        {transactionData.to === undefined ? "未知" : transactionData.to}
                    </Cell>
                    <Cell subhead={"币种(Symbol)"}>
                        {transactionData.symbol === undefined ? "未知" : transactionData.symbol}
                    </Cell>
                    <Cell subhead={"小数位(Decimals)"}>
                        {transactionData.decimals === undefined ? "未知" : transactionData.decimals}
                    </Cell>
                    <Cell subhead={"交易时间(BlockTimestamp)"}>
                        {transactionData.block_timestamp === undefined ? "未知" : transactionData.block_timestamp}
                    </Cell>
                </Section>
            }
            {
                (order === null || order.id === undefined) &&
                <Section>
                    <Cell>
                        该付款未查询到消费记录
                    </Cell>
                </Section>
            }
            {
                (order !== null && order.id !== undefined) &&
                <Section header="消费详情">
                    <Cell subhead={"订单ID"}>
                        {order.id === undefined ? "未知" : order.id}
                    </Cell>
                    <Cell subhead={"循环ID"}>
                        {order.order_id === undefined ? "未知" : order.order_id}
                    </Cell>
                    <Cell subhead={"用户ID"}>
                        {order.user_id === undefined ? "未知" : order.user_id}
                    </Cell>
                    <Cell subhead={"订单创建时间"}>
                        {order.create_time === undefined ? "未知" : order.create_time}
                    </Cell>
                    <Cell subhead={"订单Hash"}>
                        {order.hash === undefined ? "未知" : order.hash}
                    </Cell>
                </Section>
            }

            <Section header="补单确认">
                <Cell>
                    {inputHash == "" ? "请在上方输入hash并查询" : inputHash}
                </Cell>
                <Input header="输入用户ID" placeholder="输入用户ID"
                       value={inputUserId === 0 ? "" : inputUserId}
                       type={"number"}
                       onChange={e => setInputUserId(Number(e.target.value))}/>
                <Select header="请选择补单类型" value={inputType}
                        onChange={e => {
                            setInputType(Number(e.target.value))
                        }}
                        style={{backgroundColor: "var(--tgui--section_bg_color"}}>
                    <option value="1">月度会员</option>
                    <option value="2">季度会员</option>
                    <option value="3">永久会员</option>
                </Select>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60px"}}>
                    <Button style={{width: "80%"}} mode="filled" size="m"
                            onClick={e => {
                                WebApp.HapticFeedback.impactOccurred("soft")
                                WebApp.showConfirm("确定补单吗？\n请仔细核对用户ID!", (r) => {
                                    if (r) {
                                        WebApp.HapticFeedback.impactOccurred("soft")
                                        issue_order_api(inputHash, inputUserId, inputType)
                                            .then(res => {
                                                toast.success(res.data.message)
                                            })
                                    } else {
                                        return;
                                    }
                                })
                            }}
                    >补单</Button>
                </div>
            </Section>
            <InfoFooter/>
        </List>
    );
}

export default IssueOrder;
