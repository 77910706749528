import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@telegram-apps/telegram-ui/dist/styles.css';
import {BrowserRouter, Route, Routes,} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import {AppRoot} from '@telegram-apps/telegram-ui';
import KeepAlive, {AliveScope} from "react-activation";
import Fake from "./page/Fake";
import Main from "./page/Main";
import TeacherDetail from "./page/TeacherDetail";
import TeacherSelector from "./page/TeacherSelector";
import ErrorManagement from "./page/ErrorManagement";
import Service from "./page/Service";
import ReportManagement from "./page/ReportManagement";
import UserManagement from "./page/UserManagement";
import OutstandingSetting from "./page/OutstandingSetting";
import ErrorPage from "./page/ErrorPage";
import TeacherCreator from "./page/TeacherCreator";
import BackButton from "./component/BackButton";
import TeacherManagement from "./page/TeacherManagement";
import TeacherEdit from "./page/TeacherEdit";
import AdminSetting from "./page/AdminSetting";
import PinPage from "./page/PinPage";
import Miscellaneous from "./page/Miscellaneous";
import IssueOrder from "./page/IssueOrder";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    // </React.StrictMode>
    // platform={"ios"}
    <BrowserRouter>
        <AppRoot  style={{
            background: 'var(--tgui--secondary_bg_color)',
            minHeight: 'var(--tg-viewport-stable-height)'
        }}>
            <BackButton/>
            <Toaster/>
            <AliveScope>
                <Routes>
                    <Route path="/" element={<Fake/>}/>
                    <Route path="/Main" element={<KeepAlive cacheKey={"Main"} name={"Main"}><Main/></KeepAlive>}
                           errorElement={<ErrorPage/>}/>
                    <Route path="/TeacherSelector"
                           element={<KeepAlive cacheKey="TeacherSelector" name={"TeacherSelector"}>
                               <TeacherSelector/>
                           </KeepAlive>}
                           errorElement={<ErrorPage/>}/>
                    <Route path="/TeacherCreator" element={<TeacherCreator/>} errorElement={<ErrorPage/>}/>
                    <Route path="/TeacherDetail" element={<TeacherDetail/>} errorElement={<ErrorPage/>}/>
                    <Route path="/Service" element={<Service/>} errorElement={<ErrorPage/>}/>
                    <Route path="/ErrorManagement"
                           element={<KeepAlive cacheKey="ErrorManagement" name={"ErrorManagement"}>
                               <ErrorManagement/>
                           </KeepAlive>}
                           errorElement={<ErrorPage/>}/>
                    <Route path="/ReportManagement"
                           element={<KeepAlive cacheKey="ReportManagement" name={"ReportManagement"}>
                               <ReportManagement/>
                           </KeepAlive>}
                           errorElement={<ErrorPage/>}/>
                    <Route path="/TeacherManagement" element={<TeacherManagement/>} errorElement={<ErrorPage/>}/>
                    <Route path="/TeacherEdit" element={<TeacherEdit/>} errorElement={<ErrorPage/>}/>
                    <Route path="/UserManagement" element={<UserManagement/>} errorElement={<ErrorPage/>}/>
                    <Route path="/OutstandingSetting" element={<OutstandingSetting/>} errorElement={<ErrorPage/>}/>
                    <Route path="/AdminSetting" element={<AdminSetting/>} errorElement={<ErrorPage/>}/>
                    <Route path="/PinPage" element={<PinPage/>} errorElement={<ErrorPage/>}/>
                    <Route path="/Miscellaneous" element={<Miscellaneous/>} errorElement={<ErrorPage/>}/>
                    <Route path="/IssueOrder" element={<IssueOrder/>} errorElement={<ErrorPage/>}/>
                    <Route path="/*" element={<ErrorPage/>}/>
                </Routes>
            </AliveScope>
        </AppRoot>
    </BrowserRouter>
);
